import clsx from 'clsx';
import React from 'react';

export interface SwitchIconProps {
  icon: JSX.Element;
  position?: 'left' | 'right' | 'top' | 'bottom';
}

export type SwitcherOptionType = {
  text?: string;
  subText?: string;
  value: string;
  icon?: SwitchIconProps;
};

export enum SwitcherVariants {
  Icon = 'Icon',
  Text = 'Text',
  Number = 'Number',
}

export interface SwitcherProps {
  options: SwitcherOptionType[];
  variant?: SwitcherVariants;
  value: string;
  onChange: (option: string) => void;
  'aria-label': string;
}

export const Switcher: React.FC<SwitcherProps> = ({
  options,
  value,
  onChange,
  variant = SwitcherVariants.Text,
  'aria-label': ariaLabel,
}) => {
  const valueIndex = options.findIndex((option) => option.value === value);
  const [sliding, setSliding] = React.useState(false);

  return (
    <div
      aria-label={ariaLabel}
      role="group"
      className="relative box-border flex flex-grow rounded-full border-[1px] border-neutral-300 bg-neutral-100"
    >
      <div className="relative flex flex-grow p-xs">
        <div
          className="pointer-events-none absolute flex items-center justify-center rounded-full bg-brandMidnight shadow-md transition-transform duration-150 ease-in-out"
          style={{
            width: `calc((100% - 4px) / ${options.length} - 4px)`,
            height: 'calc(100% - 8px)',
            transform: `translateX(calc((100% * ${valueIndex}) + (4px * ${valueIndex}) ))`,
          }}
        />
        <div className="flex flex-grow gap-xs">
          {options.map((option, index) => {
            const checked = option.value === value;
            const icon = option?.icon?.icon || null;
            return (
              <section
                role="button"
                aria-label={`${option.text}`}
                key={`${option.value}-${index}`}
                className={clsx(
                  'relative flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap rounded-full',
                  {
                    'flex-col':
                      option?.icon &&
                      (option.icon.position === 'top' ||
                        option.icon?.position === 'bottom'),
                    'text-neutral-0': checked,
                  }
                )}
                onClick={() => {
                  setSliding(true);
                  onChange(option.value);
                  setTimeout(() => {
                    setSliding(false);
                  }, 150);
                }}
              >
                <div
                  className={clsx(
                    'group relative flex h-full w-full items-center justify-center rounded-full',
                    {
                      'px-m py-[13px]': variant === SwitcherVariants.Number,
                      'p-m': variant !== SwitcherVariants.Number,
                    }
                  )}
                >
                  <div
                    className={clsx(
                      'absolute left-[50%] top-0 z-0 h-full w-full translate-x-[-50%] rounded-full',
                      {
                        'group-hover:bg-neutral-200': !checked,
                        'group-hover:bg-brandMidnight-hover':
                          checked && !sliding,
                        'flex-col gap-s text-center': option.subText,
                      }
                    )}
                  />
                  <div className="z-10">
                    {option.icon &&
                      (option.icon.position === 'left' ||
                        option.icon.position === 'top') &&
                      icon}
                    {option.text && (
                      <div
                        className={clsx('flex flex-col text-center', {
                          'text-body1Light': !checked,
                          'text-body1Medium': checked,
                        })}
                      >
                        {option.text}
                        {option.subText && <div>{option.subText}</div>}
                      </div>
                    )}
                    {option.icon &&
                      option.icon.position !== 'left' &&
                      option.icon.position !== 'top' &&
                      icon}
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      </div>
    </div>
  );
};
